import React from 'react';
import {
    message,
    Button,
    Form,
    Input,
    InputNumber,
    Radio,
    Tabs,
    Tag,
    Switch
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import UploadImage from '../upload/Image';
import UploadImageList from '../upload/ImageList';
import UploadVideo from '../upload/Video';
import LoadingLayer from '../common/LoadingLayer';
import HtmlEditor from '../common/HtmlEditor';
import RandomUtil from '../../util/RandomUtil';
import { getInfo, updateProduct } from '../../api/ProductApi';
import LocalStorageUtil from '../../util/LocalStorageUtil';

const { TabPane } = Tabs;
let detailHtml = '';

class UpdateMallProduct extends React.Component {

    state = {
        id: this.props.value,
        name: '',
        subTitle: '',
        brandId: 0,
        productSn: '',
        pic: '',
        albumPics: [],
        originalPrice: 0,
        enabledStatus: 0,
        showTime: [],
        promotionStartTime: null,
        promotionEndTime: null,
        detailHtml: '',
        skuStockList: [],
        sort: 0,
        delaySwitch: false,
        delayHour: 72,
        delayVoucherId: null,
        noCoupon: false,
        topVideo: '',
        loading: false,
        tip: ''
    };

    componentDidMount = async _ => {
        this.setState({
            loading: true,
            tip: '数据加载中'
        });
        let response = await getInfo(this.state.id);
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                name: data.name,
                subTitle: data.subTitle,
                description: data.description,
                brandId: data.brandId,
                brandName: data.brandName,
                productSn: data.productSn,
                pic: data.pic,
                albumPics: data.albumPics ? data.albumPics.split(',').map(item => {
                    return {
                        url: item,
                        id: RandomUtil.randomString(16)
                    };
                }) : [],
                originalPrice: data.originalPrice,
                enabledStatus: data.enabledStatus,
                detailHtml: data.detailHtml,
                skuStockList: data.skuStockList ? data.skuStockList : [],
                sort: data.sort,
                delaySwitch: data.delaySwitch ? data.delaySwitch : false,
                delayHour: data.delayHour ? data.delayHour : 72,
                delayVoucherId: data.delayVoucherId,
                noCoupon: data.noCoupon,
                topVideo: data.topVideo
            });
            detailHtml = data.detailHtml;
        }
        this.setState({
            loading: false
        });
        this.getChangeStorage();
    }

    setChangeStorage = _ => {
        let data = this.state;
        LocalStorageUtil.setWithExpire('productChangeStorage_' + this.state.id, data, 300);
    }

    getChangeStorage = _ => {
        let data = LocalStorageUtil.get('productChangeStorage_' + this.state.id);
        if (data && window.confirm('是否使用草稿？')) {
            this.setState({
                name: data.name,
                subTitle: data.subTitle,
                brandId: data.brandId,
                productSn: data.productSn,
                pic: data.pic,
                albumPics: data.albumPics,
                originalPrice: data.originalPrice,
                enabledStatus: data.enabledStatus,
                skuStockList: data.skuStockList,
                sort: data.sort,
                noCoupon: data.noCoupon
            });
        } else {
            LocalStorageUtil.remove('productChangeStorage_' + this.state.id);
        }
    }

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            }, _ => this.setChangeStorage());
        },
        onSubTitleChange: e => {
            this.setState({
                subTitle: e.target.value
            }, _ => this.setChangeStorage());
        },
        onBrandIdChange: id => {
            this.setState({
                brandName: id
            }, _ => this.setChangeStorage());
        },
        onProductSnChange: e => {
            this.setState({
                productSn: e.target.value
            }, _ => this.setChangeStorage());
        },
        onPicChange: value => {
            this.setState({
                pic: value
            }, _ => this.setChangeStorage());
        },
        onAlbumPicsChange: files => {
            this.setState({
                albumPics: files
            }, _ => this.setChangeStorage());
        },
        onTopVideoChange: value => {
            this.setState({
                topVideo: value
            }, _ => this.setChangeStorage());
        },
        onOriginalPriceChange: value => {
            this.setState({
                originalPrice: value
            }, _ => this.setChangeStorage());
        },
        onPublishStatusChange: e => {
            this.setState({
                enabledStatus: e.target.value
            }, _ => this.setChangeStorage());
        },
        onDetailHtmlChange: value => {
            detailHtml = value;
        },
        onSortChange: number => {
            this.setState({
                sort: number
            }, _ => this.setChangeStorage());
        },
        onSkuPicChange: (index, pic) => {
            let skuStockList = this.state.skuStockList;
            skuStockList[index].pic = pic;
            this.setState({
                skuStockList: skuStockList
            }, _ => this.setChangeStorage());
        },
        onSkuPromotionPriceChange: (index, number) => {
            let skuStockList = this.state.skuStockList;
            skuStockList[index].promotionPrice = number;
            this.setState({
                skuStockList: skuStockList
            }, _ => this.setChangeStorage());
        },
        onSkuOfficialPriceChange: (index, number) => {
            let skuStockList = this.state.skuStockList;
            skuStockList[index].officialPrice = number;
            this.setState({
                skuStockList: skuStockList
            }, _ => this.setChangeStorage());
        },
        onNoCouponChange: status => {
            this.setState({
                noCoupon: status
            })
        },
        onDelaySwitchChange: status => {
            this.setState({
                delaySwitch: status
            })
        },
        onDelayHourChange: number => {
            this.setState({
                delayHour: number
            })
        },
        onDelayVoucherIdChange: number => {
            this.setState({
                delayVoucherId: number
            })
        },
        onSubmit: async _ => {
            this.setState({
                loading: true,
                tip: '提交中'
            });
            let data = JSON.parse(JSON.stringify(this.state));
            data.albumPics = data.albumPics.map(item => item.url).join(',');
            data.detailHtml = detailHtml;
            let response = await updateProduct(this.state.id, data);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                LocalStorageUtil.remove('productChangeStorage_' + this.state.id);
                message.success('商品更新成功');
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
            }
        }
    }

    render() {
        let skuTr = this.state.skuStockList.map((item, index) => (
            <tr key={item.skuCode}>
                <td>{item.skuCode}</td>
                <td>{JSON.parse(item.spData).map(i => <Tag>{i.key} : {i.value}</Tag>)}</td>
                <td><UploadImage value={item.pic} onChange={this.formHandler.onSkuPicChange.bind(this, index)} /></td>
                <td>{item.stock}</td>
                <td>{item.price}</td>
                <td><InputNumber min="0" value={item.promotionPrice ? item.promotionPrice : 0} onChange={this.formHandler.onSkuPromotionPriceChange.bind(this, index)}/></td>
                <td><InputNumber min="0" value={item.officialPrice ? item.officialPrice : 0} onChange={this.formHandler.onSkuOfficialPriceChange.bind(this, index)}/></td>
            </tr>
        ));


        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title="编辑商品信息"
                />
                <Tabs defaultActiveKey="base">
                    <TabPane tab="基础信息" key="base">
                        <Form
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                            style={{ marginTop: 10 }}
                        >
                            <Form.Item label="商品名称">
                                <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                            </Form.Item>
                            <Form.Item label="副标题">
                                <Input value={this.state.subTitle} onChange={this.formHandler.onSubTitleChange}/>
                            </Form.Item>
                            {/* <Form.Item label="品牌">
                                <BrandSelector value={this.state.brandId} onChange={this.formHandler.onBrandIdChange}/>
                            </Form.Item> */}
                            {/* <Form.Item label="货号">
                                <Input value={this.state.productSn} onChange={this.formHandler.onProductSnChange}/>
                            </Form.Item> */}
                            {/* <Form.Item label="市场价">
                                <InputNumber min={0} precision={2} value={this.state.originalPrice} onChange={this.formHandler.onOriginalPriceChange}/>
                            </Form.Item> */}
                            <Form.Item label="上架状态">
                                <Radio.Group value={this.state.enabledStatus} onChange={this.formHandler.onPublishStatusChange}>
                                    <Radio value={0}>下架</Radio>
                                    <Radio value={1}>上架</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="不使用优惠券">
                                <Switch checked={this.state.noCoupon} onChange={this.formHandler.onNoCouponChange}/>
                            </Form.Item>
                            <Form.Item label="晚发必赔">
                                <Switch checked={this.state.delaySwitch} onChange={this.formHandler.onDelaySwitchChange}/>
                            </Form.Item>
                            {this.state.delaySwitch &&
                                <>
                                    <Form.Item label="赔付时间">
                                        <InputNumber value={this.state.delayHour} onChange={this.formHandler.onDelayHourChange}/>
                                    </Form.Item>
                                    <Form.Item label="赔付券ID">
                                        <InputNumber value={this.state.delayVoucherId} onChange={this.formHandler.onDelayVoucherIdChange}/>
                                    </Form.Item>
                                </>
                            }
                            <Form.Item wrapperCol={{ offset: 2 }}>
                                <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="封面信息" key="cover">
                        <Form
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                            style={{ marginTop: 10 }}
                        >
                            <Form.Item label="封面图片">
                                <UploadImage value={this.state.pic} onChange={this.formHandler.onPicChange}/>
                            </Form.Item>
                            <Form.Item label="商品轮播图">
                                <UploadImageList value={this.state.albumPics} onChange={this.formHandler.onAlbumPicsChange}/>
                            </Form.Item>
                            <Form.Item label="商品封面视频">
                                <UploadVideo value={this.state.topVideo} onChange={this.formHandler.onTopVideoChange} />
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 2 }}>
                                <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="规格和属性及SKU" key="attribute">
                        <Form
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                            style={{ marginTop: 10 }}
                        >
                            <Form.Item wrapperCol={{ offset: 2 }}>
                                <table border="1" cellpadding="10">
                                    <tr>
                                        <th>规格编号</th>
                                        <th>规格</th>
                                        <th>库存</th>
                                        <th>图片</th>
                                        <th>入库价</th>
                                        <th>销售价</th>
                                        <th>官方价</th>
                                    </tr>
                                    {skuTr}
                                </table>
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 2 }}>
                                <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="详情信息" key="detail">
                        <Form
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                            style={{ marginTop: 10 }}
                        >
                            <Form.Item label="详情">
                                <HtmlEditor defaultValue={this.state.detailHtml} onChange={this.formHandler.onDetailHtmlChange}/>
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 2 }}>
                                <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                </Tabs>
            </>
        );
    }
}

export default UpdateMallProduct;
